<template>
  <div class="text-left">
    <div class="flex flex-wrap pr-6">
      <div class="w-1/2 pl-6 mb-6">
        <upload
            v-if="donnes.ordinanceUrls !== null"
          :icon="icons.ordonnance"
          background="#FBFBFB"
          border="1px dashed #C4C4C4"
          radius="5px"
            height="68px"
          label="Ordonnance"
          color="#606060"
            @oga="document(donnes.ordinanceUrls[0])"
        />
      </div>

      <div
          class="w-1/2 pl-6 mb-6"
          v-for="(item, index) in donnes.documentUrls"
          :key="index"
      >
        <upload
          :icon="icons.document"
          background="#FBFBFB"
          height="68px"
          border="1px dashed #C4C4C4"
          radius="5px"
          :label="'Document ' + (index + 1)"
          color="#606060"
          :index="index"
          :item="item"
          @oga="document"
          @deleter="delDoc"
        />
      </div>
    </div>

    <div class="mt-10">
      <div>Assureurs/Maisons partenaires</div>
      <div class="mt-2 bg-f5 rounded-5 inputo flex pl-4 pr-4 items-center">
        <div
            v-for="(item, index) in donnes.insuranceSupports"
            :key="index"
        >
          <bouton
              :icon="icons.icDelete"
              iconHeight="15"
              :second="true"
              :label="item.insurance.name"
              padding="0px 10px 0px 10px"
              height="30px"
              border="0.5px solid #DDDDDD"
              size="12px"
              background="#E9FFEE"
              color="black"
              weight="400"
              radius="4px"
              @info="backDelete(item)"
          />
        </div>
      </div>
    </div>

    <div class="mt-10">
      <div>Note</div>
      <div class="mt-2 bg-f5 rounded-5 h-108 p-4">
        <span v-if="donnes.meta.note !== undefined">
          {{ donnes.meta.note }}
        </span>
      </div>
    </div>
  </div>
</template>

<script>
import document from '../../../assets/icons/doc.svg'
import ordonnance from '../../../assets/icons/file.svg'
import upload from './showDocument'
import bouton from '../../helper/add/button'
import icDelete from '../../../assets/icons/delete.svg'
import http from '../../../plugins/https'
import apiroutes from '../../../router/api-routes'

export default {
  name: "Index",

  components: {
    upload,
    bouton
  },
  props: {
    donnes: {
      type: Object,
      default: null
    },
  },

  data () {
    return {
      icons: {
        document,
        ordonnance,
        icDelete
      },
      devis: null,
      allDoc: []
    }
  },

  created () {
    this.allDoc = this.donnes.documentUrls
  },

  methods: {
    document (item) {
      window.open(item, "_blank");
    },

    delDoc(a) {
      this.allDoc.splice(a.index, 1)
      this.updateEstimation()
    },

    updateEstimation () {
      const body = {
        id: this.donnes.id,
        documentUrls: this.allDoc
      }
console.log(body)
      http.post(apiroutes.baseURL + apiroutes.mesure, body)
          .then(response => {
            console.log('save update')
            console.log(response)
          })
          .catch(error => {
            this.charge = false
            console.log(error)
          })
    },

    backDelete(a) {
      this.$emit('removeAs', a)
    }
  }
}
</script>

<style lang="scss" scoped>
.svg-icon {
  fill: white;
}

.inputo {
  background: #FFFFFF;
  border: 0.5px solid #DDDDDD;
  box-sizing: border-box;
  border-radius: 5px;
  height: 50px;
}
</style>
