<template>
  <section class="success">
    <popup-base
      v-show="wantToMakeOffer"
      class="popo md:pt-10 md:pb-4 w-full"
      @clickOnBackground="logState"
    >
      <div class="cardinal mr-auto ml-auto p-5">
        <div
          class="success-container h-full p-10"
        >
          <div class="text-center text-c18 font-c4 mt-6">
            <icon
                :data="icons.add"
                height="60"
                width="60"
                class="cursor-pointer"
                original
            />

            <div class="text-c24 mt-10">Ajouter la 2ème paire au devis</div>
            <div
              class="pl-8 pr-8 text-c18 font-c4 mt-6"
            >
              Le client bénéficie d’une deuxième paire, utiliser maintenant  ?
            </div>
          </div>

          <div v-if="error !== null" class="text-red text-c14 mt-6">{{error}}</div>
          <div class="mt-12 flex justify-center">

            <div class="w-1/2">
              <buton
                label="Non, plutard"
                background="#EFEFEF"
                color="#000"
                size="16px"
                weight="600"
                radius="5px"
                @oga="fermer"
              />
            </div>

            <div class="w-1/2 ml-6">
              <buton
                  label="Oui, ajouter"
                  size="16px"
                  weight="600"
                  radius="5px"
                  @oga="$emit('oga', true)"
              />
            </div>
          </div>
        </div>
      </div>
    </popup-base>
  </section>
</template>

<script>
import PopupBase from '../helper/add/popupfat'
import buton from '../helper/add/button'
import add from '../../assets/icons/add3.svg'
import fermer from '../../assets/icons/fermer.svg'

export default {
  name: 'Success',
  components: { PopupBase, buton },
  props: {
    activation: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      wantToMakeOffer: false,
      icons: {
        add,
        fermer
      },
      error: null,
      position: -1
    }
  },
  created() {
    this.wantToMakeOffer = this.activation
  },
  methods: {
    fermer () {
      this.$emit('oga', false)
    },

    logState (answer) {
      this.wantToMakeOffer = answer
      this.$emit('oga', false)
    }
  }
}
</script>

<style lang="scss"  scoped>
    @import "../../assets/styles/sass/mixin";
    .success {
      z-index: 999;
    }
    .cardinal {
      width: 42%;
    }
    .agence {
      background: #EFF3FE;
      border: 1px solid #DDDDDD;
      box-sizing: border-box;
      border-radius: 8.03241px;
      height: 79.52px;
    }

    .success-container {
      font-family: $font-default;
      width: 100%;
      background-color: $white;
      height: auto;
      margin: auto;
      border-radius: 12px;
    }
    .inputo {
      border: 0.855148px solid #DDDDDD;
      box-sizing: border-box;
      border-radius: 4.27574px;
      font-weight: 500;
      font-size: 16px;
      line-height: 180%;
      color: #F2542D;
    }
    .activate {
      background-color: #3521B5;
      color: white;
    }
    @media screen and (max-width: 700px){
      .success-container {
        width: 100%;
        height: 100vh;
      }
      .titre {
        font-size: 18px;
      }
      .bloc1 {
        width: 100%;
      }
      .bloc2 {
        width: 100%;
        font-size: 18px;
      }
      .button {
        width: 100%;
        font-size: 16px;
      }
      .cardinal {
        width: 100%;
      }
    }

    @media screen and (max-width: 1024px) and (min-width: 770px){
      .cardinal{
        width: 65%;
      }
      .titre{
        font-size: 18px;
      }
      .button{
        width: 100%;
        height: 55px;
        font-size: 19px;
      }
    }

    @media screen and (max-width: 769px) and (min-width: 701px){
      .cardinal{
        width: 90%;
      }
      .titre{
        font-size: 18px;
      }
      .button{
        width: 100%;
        height: 55px;
        font-size: 19px;
      }
    }
    @media screen and (max-width: 320px){
      .cardinal{
        width: 100%;
      }
      .titre{
        font-size: 14px;
      }
      .button{
        width: 100%;
        height: 55px;
        font-size: 15px;
      }
    }
</style>
