<template>
  <div>
    <paiement :activation="activePaye" :donne="devis" v-if="activePaye" @oga="retourPaye"/>
    <assurance :activation="activeAssurance" :donnes="devis" v-if="activeAssurance" @oga="retourAssurance"/>
    <success-paye :activation="activeSuccess" v-if="activeSuccess" @oga="retourSuccess"/>
    <mesure :activation="activeMesure" v-if="activeMesure" :donnes="devis" @oga="retourMesure"/>
    <update-mesure :activation="activeUpdateMesure" v-if="activeUpdateMesure" :donnes="devis" @oga="retourMesure"/>
    <reduction :activation="activeRemise" :donnes="devis" v-if="activeRemise" @oga="activeRemise = false"/>
    <addReduction :activation="activeReduction" :donnes="devis" v-if="activeReduction" @oga="backReduction"/>
    <addDelivre :activation="activeDelivre" :donnes="devis" v-if="activeDelivre" @oga="backDelivre"/>
    <confirm :activation="activeConfirm" :message="message" v-if="activeConfirm" @oga="retourConfirm"/>

    <div class="flex">
      <div class="premier bloc bg-fb rounded-6">
        <div class="w-full" id="pdf">
          <div class="p-10">
            <div class="flex">
              <div class="w-4/5" @click="activeDetail =! activeDetail">
                <div
                    v-if="charge && client.length === 0"
                    class="flex justify-center mt-16"
                >
                  <easy-spinner
                      type="dots"
                      size="40"
                  />
                </div>

                <div class="flex items-center" v-if="devis !== null">
                  <label-name :first="devis.client.name" :second="devis.client.surname" height="40px" width="40px"/>
                  <div class="ml-4 text-c18 text-left">
                    <div>{{ devis.client.name }} {{ devis.client.surname }} </div>
                    <div class="text-90 text-c16">
                      {{ devis.client.phone }} | {{ devis.client.city }}, {{ devis.client.address }}
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="mt-6" v-if="valueClient !== null">
              <div class="flex items-center">
                <div class="text-c24 font-c6">
                  Devis Nº {{devis.reference}}
                </div>
                <div class="ml-4 w-1/6">
                  <bouton
                      label="En cours"
                      height="32px"
                      color="#FBA705"
                      background="#FFF6E6"
                      radius="10px"
                      size="14px"
                      v-if="devis.state === 'IN_PROGRESS' && conversion(2) === false && expirate() === false && devis.order === null"
                  />
                  <bouton
                      label="Acceptée"
                      height="32px"
                      color="#00C24E"
                      background="#E5F9ED"
                      radius="10px"
                      size="14px"
                      v-if="devis.order !== null"
                  />

                  <bouton
                      label="A rappeler"
                      height="32px"
                      color="#F82626"
                      background="#FEE9E9"
                      radius="10px"
                      size="14px"
                      v-if="devis.state === 'IN_PROGRESS' && conversion(2) === true && expirate() === false && devis.order === null"
                  />

                  <bouton
                      label="Devis expiré"
                      height="32px"
                      color="#27053F"
                      background="#E9E6EC"
                      radius="10px"
                      size="14px"
                      v-if="devis.state === 'IN_PROGRESS' && expirate() === true && devis.order === null"
                  />
                </div>
              </div>

              <div class="flex mt-6">
                <div class="w-1/2">
                  <div class="inputo text-left flex items-center bg-white pl-4 text-c14">
                    <span>{{ startDate.toLocaleDateString() }}</span>
                  </div>
                  <div class="bg-fb text-90 text-c12 mouv rounded-5 w-1/3 ml-3">
                    Date du devis
                  </div>
                </div>

                <div class="w-1/2 ml-6">
                  <div class="inputo text-left flex items-center bg-white pl-4 text-c14">
                    <span>{{ expiration.toLocaleDateString() }}</span>
                  </div>
                  <div class="bg-fb text-90 text-c12 mouv rounded-5 w-2/5 ml-3">
                    Date d’expiration
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="diviser" />

          <div class=" p-10"  v-if="valueClient !== null">
            <article-liste :donnes="devis" />

            <div class="mt-6">
              <medecin :donnes="devis" @oga="activeRemise = true" @action="backRemise"/>
            </div>

            <div class="mt-4">
              <ordonnance-liste  @removeAs="backDelete" :donnes="devis"/>
            </div>
          </div>

          <div class="diviser" />

          <div class="p-10 pt-6 pb-6 text-c14 text-60 text-left" v-if="valueClient !== null">
            <span class="text-black font-c5">Agence: </span>  <span class="ml-2">OHO Les Lunettes, <span v-if="agence">{{ devis.agency.label.substr(0, 1).toUpperCase() }}{{ devis.agency.label.substr(1, devis.agency.label.length) }}</span> </span>
            <span class="ml-6 text-black font-c5">Commercial(e): </span>
            <span class="ml-2" v-if="devis.seller !== null && devis.seller !== undefined">
              {{ devis.seller.fullname.substr(0, 30) }}
              <span v-if="devis.seller.fullname.length > 30">...</span>
            </span>
          </div>
        </div>
      </div>

      <div class="second ml-4">
        <div class="w-full">
          <div class="bloc bg-white rounded-6 p-6 text-left">
            <div class="flex text-c16">
              <div class="text-90 w-1/2">Articles</div>
              <div class="w-1/2 font-c7 ml-4">
                <span v-if="devis !== null && devis.articles !== undefined">
                  {{devis.articles.length}}
                </span>
                <span v-if="devis === null">00</span>
              </div>
            </div>

            <div class="flex text-c16 mt-4">
              <div class="text-90 w-1/2">
                Montant total
              </div>
              <div class="w-1/2 font-c7 ml-4">
                {{ (devis.financialData.totalAmount - devis.financialData.discountAmount + devis.financialData.deliveryAmount - devis.financialData.supportAmount).toLocaleString() }} Fr
              </div>
            </div>
          </div>

          <div v-if="devis.insuranceSupports.length > 0" class="mt-6">
            <div class="ml-2 w-full font-c5 text-c18 text-left">
              Document de prise en charge
            </div>

            <div
                v-for="(item, index) in devis.insuranceSupports"
                :key="index"
                class="text-left p-4 cursor-pointer flex items-center mt-6"
                :style="{'height': '68px', 'border': '1px dashed #C4C4C4', 'color': '#606060', 'border-radius': '5px', 'background-color': '#FBFBFB'}"
                @click="openUpload(item.meta.referenceUrl)"
            >
              <div class="flex items-center w-full">
                <div class="w-10">
                  <icon
                      :data="icons.documents"
                      height="30"
                      width="30"
                      class="cursor-pointer text-center"
                      original
                  />
                </div>

                <div class="ml-2 w-4/5 font-c5 text-c14">
                  {{ item.insurance.name }}
                </div>
              </div>
            </div>
          </div>

          <div class="bloc bg-white rounded-6 p-6 mt-6 text-left">
            <div class="text-c16 text-90">
              Commercial externe
            </div>

            <div class="mt-4 flex items-center blocCom p-3">
<!--              <multiselect :icon="icons.user" value-t="" :writeOption="true" width="85%" :option="medecin" background="white" height="50px" />-->
              <icon
                  :data="icons.user"
                  height="35"
                  width="35"
                  class="cursor-pointer"
                  original
              />
              <div class="ml-3 text-left text-c16" v-if="devis.commercial !== null">
                {{devis.commercial.fullname}}
              </div>
            </div>
          </div>

          <div>
            <boutonZone @imprime="downloadPDF" @payement="retourPayement" @mesure="activeMesure = true" @updateMesure="activeUpdateMesure = true" :donnes="devis" @assurance="activeAssurance = true"/>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import eye from '../../../assets/icons/eye.svg'
import bouton from '../../helper/add/button'
import add from '../../../assets/icons/add2.svg'
import user from '../../../assets/icons/user-circle.svg'
import update from '../../../assets/icons/update.svg'
import articleListe from './articleListe'
import ordonnanceListe from './ordonnanceListe'
import apiroutes from '../../../router/api-routes'
import http from '../../../plugins/https'
// import multiselect from '../../helper/form/multiselect'
import labelName from '../../helper/add/nameLabel'
import medecin from './medecinSession'
import boutonZone from './boutonZone'
import paiement from '../../popup/recharge'
import assurance from '../../popup/priseEnCharge'
import successPaye from '../../popup/successPaye'
import mesure from '../../popup/priseDeMesure'
import reduction from '../../popup/reduction'
import updateMesure from '../../popup/updateMesure'
import documents from '../../../assets/icons/doc.svg'
import addReduction from '../../popup/addReduction'
import addDelivre from '../../popup/addDelivre'
import confirm from '../../popup/confirm'

export default {
  name: "Index",

  components: {
    bouton,
    labelName,
    articleListe,
    ordonnanceListe,
    // multiselect,
    medecin,
    boutonZone,
    paiement,
    assurance,
    successPaye,
    mesure,
    reduction,
    updateMesure,
    addReduction,
    addDelivre,
    confirm
  },
  props: {
    donnes: {
      type: Object,
      default: null
    }
  },

  data () {
    return {
      icons: {
        eye,
        add,
        update,
        user,
        documents
      },
      client: [],
      valueClient: null,
      activeDetail: false,
      activeClient: false,
      charge: false,
      dataClient: [],
      expiration: null,
      startDate: null,
      devis: null,
      medecin: ['ABOU Rolland', 'Merille SODA', 'Cédric BOUTAN', 'Vincent MOUNCI'],
      activePaye: false,
      activeAssurance: false,
      activeSuccess: false,
      activeMesure: false,
      agence: '',
      user: null,
      activeRemise: false,
      activeUpdateMesure: false,
      activeReduction: false,
      url: null,
      activeDelivre: false,
      selectedItem: null,
      activeConfirm: false,
      message: 'Êtes-vous sûr de vouloir supprimer cette prise en charge ?'
    }
  },

  computed: {
    amount: function () {
      let data = this.devis.articles
      let amount = 0
      if (data !== null) {
        for (let o in data) {
          amount = amount + (parseInt(data[o].price.amount) * parseInt(data[o].quantity))
        }
      }
      return amount.toLocaleString()
    }
  },

  created () {
    this.devis = this.donnes
    this.valueClient = this.devis

    this.startDate = new Date()
    if (this.devis !== null) {
      this.startDate = new Date(this.devis.created_at)
      this.expiration = new Date(this.devis.expireDate)
    }
  },

  mounted(){
    this.agence = this.$store.getters.trackAgence.toLowerCase().replace('agence', '')
    if (this.agence.substr(0, 1) === ' '){
      this.agence = this.agence.substr(1, this.agence.length)
    }
    if (this.$store.getters.token !== null){
      this.user = this.parseJwt(this.$store.getters.token)
    }
  },

  methods: {
    backDelete (a) {
      this.activeConfirm = true
      this.selectedItem = a
    },

    retourConfirm(a){
      if (a) {
        this.deleteSupport()
      }
      this.activeConfirm = false
    },

    deleteSupport () {
      console.log('la requête de suppression ')
      this.charge = false
      http.post(apiroutes.baseURL + apiroutes.deleteInsurance, {
        "id": this.selectedItem.id,
      })
          .then(response => {
            this.$emit('oga', true)
            console.log(response)
            this.charge = false
          })
          .catch(error => {
            this.charge = false
            console.log(error)
          })
    },

    backRemise(r) {
      if (r === 'REMISE') {
        this.activeReduction = true
      } else {
        this.activeDelivre = true
      }
    },

    backReduction(a) {
      if (a) {
        this.$emit('oga', true)
      }
      this.activeReduction = false
    },

    backDelivre(a) {
      if (a) {
        this.$emit('oga', true)
      }
      this.activeDelivre = false
    },


    conversion (dure) {
      const temps = dure * (1000 * 3600 * 24)
      const count = new Date(this.devis.created_at).getTime() + temps
      const now = new Date().getTime()
      if (now >= count ) {
        return true
      } else {
        return false
      }
    },

    downloadPDF () {
      this.charge = true
      http.post(apiroutes.baseURL + apiroutes.generatePdf, {
        id: this.devis.id
      })
          .then(response => {
            window.open(response.url, "_blank");
            this.charge = false
          })
          .catch(error => {
            this.charge = false
            console.log(error)
          })

    },

    expirate () {
      const now = new Date().getTime()
      const expire = new Date(this.devis.expireDate).getTime()

      if (now >= expire) {
        return true
      } else {
        return false
      }
    },

    openUpload (url) {
      window.open(url, "_blank");
    },

    parseJwt (token) {
      const base64Url = token.split('.')[1];
      const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
      const jsonPayload = decodeURIComponent(atob(base64).split('').map(function (c) {
        return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2)
      }).join(''));
      return JSON.parse(jsonPayload)
    },

    retourUser (answer) {
      for (let item in this.dataClient) {
        if (this.dataClient[item].name + ' ' + this.dataClient[item].surname === answer) {
         const body = this.dataClient[item]
          this.saveEstimate(body)
        }
      }
    },

    saveEstimate (body) {
      http.post(apiroutes.baseURL + apiroutes.createEstimate, {
        "client": body.id,
        "expireDate": this.expiration,
        "meta": {}
      })
          .then(response => {
            this.valueClient = response
            this.devis = response
            this.charge = false
          })
          .catch(error => {
            this.charge = false
            console.log(error)
          })
    },

    retourPayement (answer) {
      this.activePaye = answer
    },

    retourPaye (answer) {
      if (answer) {
        this.activeSuccess = true
      }
      this.activePaye = false
    },

    retourAssurance (answer) {
      if (answer) {
        this.$emit('oga', true)
      }
      this.activeAssurance = false
    },

    retourSuccess (answer) {
      this.activeSuccess = answer
      this.$emit('oga', true)
    },

    retourMesure (answer) {
      if (answer) {
        this.$emit('oga', true)
      }
      this.activeMesure = false
    }
  }
}
</script>

<style lang="scss" scoped>
@import "../../../assets/styles/sass/variables.scss";
.svg-icon {
  fill: white;
}
.diviser{
  height: 2px;
  background-color: #DCDCE4;
}
.bloc {
  filter: drop-shadow(0px 4.08108px 10px rgba(0, 0, 0, 0.1));
}
.blocCom {
  filter: drop-shadow(0px 4.08108px 10px rgba(0, 0, 0, 0.1));
  border: 0.5px solid #DDDDDD;
  box-sizing: border-box;
  border-radius: 5px;
}
.mouv{
  margin-top: -57px;
  margin-bottom: 60px;
}
.premier {
  width: 70%;
}
.second {
  width: 30%;
}

.inputo{
  border: 1px solid #E8E8E8;
  box-sizing: border-box;
  height: 50px;
  border-radius: 5px;
}
.inputo:focus-within {
  border: 1px solid $hover-color!important;
}
.inputo:hover {
  border: 1px solid $hover-color!important;
}
</style>
