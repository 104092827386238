<template>
  <div class="p-10 pr-16">
    <popupCharge :activation="charge" v-if="charge" />
    <startCom :activation="activeStart" :donnes="devis" v-if="activeStart" @oga="retourStart"/>
    <paye-alert :activation="activeAlert" v-if="activeAlert" @oga="retourAlert"/>
    <payement :activation="activePaye" :donne="devis" :estimation="devis.id" v-if="activePaye" @oga="retourPaye"/>
    <seconde-paire :activation="activeSecond" v-if="activeSecond" @oga="retourSecond"/>
    <coupon :activation="activeCoupon" v-if="activeCoupon" :coupon="couponValue" @oga="retourCoupon"/>
    <success :activation="activeSuccess" v-if="activeSuccess" :devis="devis" @oga="retourSuccess"/>

    <div>
      <div class="flex items-center">
        <div class="w-3/6 flex text-left text-c34 items-center font-c7">
          <icon
            :data="icons.back"
            height="70"
            width="70"
            class="mr-2 cursor-pointer"
            original
            @click="retourBack"
          />
          <div class="ml-4">
            Devis
          </div>
        </div>

        <div class="w-3/6 flex justify-end" v-if="devis">
          <bouton label="Modifier le devis" v-if="!devis.order && !expiration()" class="w-1/2" background="#EAEAEF" color="black" weight="600" radius="4px" @oga="retourUpdate"/>
          <bouton label="Lancer en commande" v-if="!devis.order && !expiration()" class="w-1/2 ml-6" weight="600" radius="4px" size="18px" @oga="activeStart = true"/>
          <bouton label="Commande associé" v-if="devis.order" class="w-1/2 ml-6" weight="600" radius="4px" size="18px" @oga="goOrder"/>
        </div>
      </div>

      <div class="mt-6">

        <div class="w-full">
          <devis :donnes="devis" v-if="devis !== null" @oga="getDevis"/>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import back from '../../assets/icons/backBut.svg'
import add from '../../assets/icons/add.svg'
import devis from '../../component/cdevs/detailsDevis/devis'
import apiroutes from '../../router/api-routes'
import http from '../../plugins/https'
import popupCharge from '../../component/popup/popupCharge'
import bouton from '../../component/helper/add/button'
import startCom from '../../component/popup/startCommande'
import payeAlert from '../../component/popup/alertPaye'
import payement from '../../component/popup/payement'
import secondePaire from '../../component/popup/secondePaire'
import coupon from '../../component/popup/coupon'
import success from '../../component/popup/successCommande'

export default {
  name: "Index",

  components: {
    devis,
    popupCharge,
    bouton,
    startCom,
    payeAlert,
    payement,
    secondePaire,
    coupon,
    success
  },

  data () {
    return {
      icons: {
        back,
        add
      },
      charge: true,
      devis: null,
      activeStart: false,
      activeAlert: false,
      activePaye: false,
      activeSecond: false,
      activeCoupon: false,
      couponValue: null,
      activeSuccess: false,
      coup: null
    }
  },

  created () {
    if (this.$route.query.x !== undefined) {
      this.getDevis()
    }
    this.$store.dispatch('saveDevisUser', null)
    this.$store.dispatch('saveCoupon', null)
  },

  methods: {
    getDevis () {
      this.devis = null
      this.charge = true
      http.get(apiroutes.baseURL + apiroutes.allDevis+ '?id=' + this.$route.query.x)
          .then(response => {
            console.log('detail devis')
            console.log(response)
            this.devis = response
            this.charge = false
          })
          .catch(error => {
            this.charge = false
            console.log(error)
          })
    },

    goOrder () {
      if (this.devis.order) {
        this.$router.push({ path: '/detailsCommande', query: { x: this.devis.order.id } })
      }
    },

    retourBack () {
      this.$router.push('/devis')
    },

    retourUpdate () {
      this.$store.dispatch('saveUpdate', this.devis)
      this.$router.push('/modifierDevis')
    },

    retourSecond (answer) {
      if (answer) {
        this.$store.dispatch('saveCoupon', this.coup)
        this.$store.dispatch('saveQuote', null)
        this.$store.dispatch('saveBasket', [])
        this.$router.push('/nouveauDevis')
      } else {
        this.activeCoupon = true
      }
      this.activeSecond = false
    },

    retourCoupon (answer) {
      if (answer) {
        this.activeSuccess = true
      }
      this.activeCoupon = false
    },

    retourPaye (answer) {
      if (answer !== false) {
        if (answer.coupon !== undefined) {
          this.activeSecond = true
          this.coup = answer
          this.couponValue = answer.coupon
        } else {
          this.activeSuccess = true
        }
      }
      this.activePaye = false
    },

    retourStart (answer) {
      if (answer) {
        this.activeAlert = true
      }
      this.activeStart = false
    },

    retourAlert (answer) {
      if (answer) {
        this.activePaye = true
      }
      this.activeAlert = false
    },

    retourSuccess (answer) {
      this.activeSuccess = answer
    },

    //track les devis expirer
    expiration () {
      const now = new Date().getTime()
      const expire = new Date(this.devis.expireDate).getTime()

      if (now >= expire) {
        return true
      } else {
        return false
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.svg-icon {
  fill: white;
}
.bloc {
  filter: drop-shadow(0px 4.08108px 10px rgba(0, 0, 0, 0.1));
}
.alert {
  background: #FFF0F0;
  border-radius: 5.10135px;
}
</style>
