<template>
  <div class="text-left">

    <div class="text-c12">Médecin ajouté</div>

    <div class="mt-4 flex items-center bloc p-3">
      <!--              <multiselect :icon="icons.user" value-t="" :writeOption="true" width="85%" :option="medecin" background="white" height="50px" />-->
      <icon
          :data="icons.user"
          height="35"
          width="35"
          class="cursor-pointer"
          original
      />
      <div class="ml-3 text-left text-c16" v-if="devis.doctor !== null">
        {{devis.doctor.fullname}}
      </div>
    </div>

<!--    <div-->
<!--        class="mt-4 flex w-4/5 mr-auto ml-auto"-->
<!--    >-->
<!--      <div class="w-full">-->
<!--        <bouton-->
<!--            label="Ajouter un article"-->
<!--            border="0.5px dashed #5138EE"-->
<!--            size="14px"-->
<!--            color="#5138EE"-->
<!--            background="#EFF4FF"-->
<!--            @oga="retourAdd"-->
<!--        />-->
<!--      </div>-->
<!--    </div>-->

    <div class="flex justify-end mt-20">
      <div class="text-right w-1/2">
        <div class="flex items-center">
          <div class="w-1/2 text-c14">Valeur Totale : </div>
          <div class="w-1/2 ml-4 bord text-c18 font-c5">{{ devis.financialData.totalAmount.toLocaleString() }} Fr </div>
        </div>

        <div class="flex mt-4 items-center">
          <div class="w-1/2 text-c14">Prise en charge :</div>
          <div class="w-1/2 ml-4 bord text-c18 font-c5">{{ devis.financialData.supportAmount.toLocaleString() }} Fr </div>
        </div>

        <div class="flex mt-4 items-center">
          <div class="w-1/2 text-c14">Remise 1 : </div>
          <div class="w-1/2 ml-4 bg-f5 rounded-5 p-1 pl-2 pr-2 text-c18 font-c5 cursor-pointer" @click="doAction('REMISE')">
            {{ devis.financialData.discountAmount.toLocaleString() }} Fr
          </div>
        </div>

<!--        <div class="flex mt-4 items-center">-->
<!--          <div class="w-1/2 text-c14">Remise 2 : </div>-->
<!--          <div class="w-1/2 ml-4">-->
<!--            <bouton label="Demander une réduction" height="24px" background="#F9C49D" color="black" size="11px" radius="6px" @oga="retourRemise"/>-->
<!--          </div>-->
<!--        </div>-->

        <div class="flex mt-4 items-center">
          <div class="w-1/2 text-c14">Frais de livraison :  </div>
          <div class="w-1/2 ml-4 bg-f5 rounded-5 p-1 pl-2 pr-2 text-c18 font-c5 cursor-pointer" @click="doAction('DELIVRE')">
            {{ devis.financialData.deliveryAmount.toLocaleString() }} Fr
          </div>
        </div>

        <div class="w-full fin mt-6 rounded-5 flex items-center font-c7">
          <div class="w-1/2 text-c14">Net à payer :  </div>
          <div class="w-1/2 ml-4 rounded-5 p-1 pl-2 pr-2 text-c18">
            {{ amount }} Fr</div>
        </div>
      </div>
    </div>

    <diviser
      height="1.8px"
      color="#5138EE"
      class="mt-10"
    />
  </div>
</template>

<script>
// import bouton from '../../helper/add/button'
import diviser from '../../helper/add/divider'
import user from '../../../assets/icons/user-circle.svg'
// import multiselect from '../../helper/form/multiselect'

export default {
  name: "Index",

  components: {
    // bouton,
    diviser,
    // multiselect
  },
  props: {
    donnes: {
      type: Object,
      default: null
    }
  },

  data () {
    return {
      icons: {
        user
      },
      devis: null,
      medecin: ['ABOU Rolland', 'Merille SODA', 'Cédric BOUTAN', 'Vincent MOUNCI'],
      activeRemise: true,
    }
  },

  computed: {
    amount: function () {
      let amount = (this.devis.financialData.totalAmount + this.devis.financialData.deliveryAmount)-(this.devis.financialData.discountAmount + this.devis.financialData.supportAmount)
      return amount.toLocaleString()
    }
  },

  created () {
    this.devis = this.donnes
  },

  methods: {
    doAction(item) {
      this.$emit('action', item)
    },

    retourRemise () {
      this.$emit('oga', true)
    },

    retourAdd () {
      this.$store.dispatch('saveUpdate', this.devis)
      this.$store.dispatch('saveBasket', this.devis.articles)
      this.$router.push('/panier')
    },

    retourPaie () {

    }
  }
}
</script>

<style lang="scss" scoped>
@import "../../../assets/styles/sass/variables.scss";
.svg-icon {
  fill: transparent;
}
.bloc {
  filter: drop-shadow(0px 4.08108px 10px rgba(0, 0, 0, 0.1));
  border: 0.5px solid #DDDDDD;
  box-sizing: border-box;
  border-radius: 5px;
}
.diviser{
  height: 1px;
  background-color: #DCDCE4;
}
.inputo{
  border: 1px solid #E8E8E8;
  box-sizing: border-box;
  height: 50px;
  border-radius: 5px;
}
.inputo:focus-within {
  border: 1px solid $hover-color!important;
}
.inputo:hover {
  border: 1px solid $hover-color!important;
}
.bord {
  border-bottom: 0.5px solid #DDDDDD;
}
.fin {
  background-color: #EFF4FF;
}

</style>
