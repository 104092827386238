<template>
  <section class="success">
    <confirm :activation="activeConfirm" :message="message" v-if="activeConfirm" @oga="retourConfirm"/>
    <popup-base
        v-if="!activeConfirm"
      v-show="wantToMakeOffer"
      class="popo md:pt-10 md:pb-4 w-full"
      @clickOnBackground="logState"
    >
      <div class="cardinal mr-auto ml-auto p-5">
        <div
          class="success-container h-full pt-6"
        >
          <div class="text-left flex pl-6 pr-6">
            <div
              class="text-c24 font-c7 w-4/5"
            >
              Livraison
            </div>
            <div class="w-1/5 flex justify-end">
              <icon
                :data="icons.fermer"
                height="30"
                width="30"
                class="cursor-pointer"
                original
                @click="fermer"
              />
            </div>
          </div>

          <div class="text-c18 font-c4 mt-6 bg-white p-6 pb-12">

            <div>
              <div class="text-left text-c12 mt-4">
                Montant de la livraison
              </div>

              <div class="mt-2">
                <amount
                    label="FCFA"
                    height="50px"
                    :value-t="0"
                  @info="retourAmount"
                />
              </div>
            </div>

            <div
              v-if="error !== null"
              class="mt-6 text-red text-c13"
            >
              {{ error }}
            </div>
          </div>

          <div class="flex">
            <div class="w-full">
              <buton
                label="Enregistrer"
                radius="0px 0px 10px 10px"
                height="55px"
                :charge="charge"
                @oga="activeConfirm = true"
              />
            </div>
          </div>
        </div>
      </div>
    </popup-base>
  </section>
</template>

<script>
import PopupBase from '../helper/add/popupfat'
import buton from '../helper/add/button'
import ic_agence from '../../assets/icons/agenceGray.svg'
import fermer from '../../assets/icons/fermer.svg'
import amount from '../helper/form/amount'
import http from "../../plugins/https"
import apiroutes from "../../router/api-routes"
import confirm from "./confirm"

export default {
  name: 'Success',
  components: { PopupBase, buton, amount, confirm },

  props: {
    activation: {
      type: Boolean,
      default: false
    },
    donnes: {
      type: Object,
      default: null
    }
  },

  data () {
    return {
      wantToMakeOffer: false,
      icons: {
        ic_agence,
        fermer
      },
      percentage: null,
      error: null,
      charge: false,
      allInfo: ['Pourcentage', 'Montant'],
      position: null,
      amount: null,
      activeConfirm: false,
      message: 'Êtes-vous sûr de vouloir appliquer les frais de livraison ?'
    }
  },

  created() {
    this.wantToMakeOffer = this.activation
  },

  methods: {
    fermer () {
      this.$emit('oga', false)
    },

    retourConfirm (answer) {
      if (answer) {
        this.continuer()
      }
      this.activeConfirm = false
    },

    continuer () {
      this.error = null
          this.charge = true

          const body = {
            id: this.donnes.id,
            deliveryAmount: this.amount,
            meta: this.donnes.meta,
            treatmentData: this.donnes.treatmentData
          }

          this.save(body)
    },

    save (body) {
      http.put(apiroutes.baseURL + apiroutes.updateDevis, body)
          .then(() => {
            this.$emit('oga', true)
            this.charge = false
          })
          .catch(error => {
            this.charge = false
            console.log(error)
            this.error = "Problème de connexion. Veuillez ressayer"
          })
    },

    retourAmount(a) {
      this.amount = a
    },

    logState (answer) {
      this.wantToMakeOffer = answer
      this.fermer()
    },
  }
}
</script>

<style lang="scss"  scoped>
    @import "../../assets/styles/sass/mixin";
    .success {
      z-index: 999;
    }
    .cardinal {
      width: 42%;
    }

    .success-container {
      font-family: $font-default;
      width: 100%;
      background-color: #F5F5F5;
      height: auto;
      margin: auto;
      border-radius: 12px;
    }
    @media screen and (max-width: 700px){
      .success-container {
        width: 100%;
        height: 100vh;
      }
      .titre {
        font-size: 18px;
      }
      .bloc1 {
        width: 100%;
      }
      .bloc2 {
        width: 100%;
        font-size: 18px;
      }
      .button {
        width: 100%;
        font-size: 16px;
      }
      .cardinal {
        width: 100%;
      }
    }

    @media screen and (max-width: 1024px) and (min-width: 770px){
      .cardinal{
        width: 65%;
      }
      .titre{
        font-size: 18px;
      }
      .button{
        width: 100%;
        height: 55px;
        font-size: 19px;
      }
    }

    @media screen and (max-width: 769px) and (min-width: 701px) {
      .cardinal {
        width: 90%;
      }
      .titre {
        font-size: 18px;
      }
      .button {
        width: 100%;
      }
    }
</style>
