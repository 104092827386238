<template>
  <section class="success">
    <popup-base
      v-show="wantToMakeOffer"
      class="popo md:pt-10 md:pb-4 w-full"
      @clickOnBackground="logState"
    >
      <div class="cardinal mr-auto ml-auto p-5">
        <div
          class="success-container h-full pt-6"
        >
          <div class="text-left flex pl-6 pr-6">
            <div
              class="text-c24 font-c7 w-4/5"
            >
              Paiement
            </div>
            <div class="w-1/5 flex justify-end">
              <icon
                :data="icons.fermer"
                height="30"
                width="30"
                class="cursor-pointer"
                original
                @click="fermer"
              />
            </div>
          </div>

          <div class="text-c18 font-c4 mt-6 bg-white p-6 pb-12">

            <div class="mt-2 text-white p-1 text-c16 bg-red">
              TOUTE SOMME ENCAISSÉE N’EST PLUS REMBOURSABLE
            </div>

            <div class="text-60 text-c12 mt-4 text-left bloc p-4">
<!--             <div>-->
<!--               Montant minimum : <span class="text-red"> {{ calcule().toLocaleString() }} Fr</span>-->
<!--             </div>-->

              <div class="">
                Prise en charge des verres : <span class="text-red"> {{ quote ('VERRE').toLocaleString() }} Fr</span>
              </div>
              <div class="mt-2">
                Prise en charge des montures : <span class="text-red"> {{ quote ('MONTURE').toLocaleString() }} Fr</span>
              </div>
            </div>

            <div v-if="!actualise">
              <div class="text-left text-c12 mt-6">
                Moyen de paiement
              </div>
              <multiselect
                  :option="allInfo"
                  background="white"
                  class="mt-2"
                  width="36%"
                  value-t="Sélectionnez le moyen utilisé"
                  @info="retourMoyen"
              />
            </div>

            <div class="mt-6" v-if="!actualise">
              <div class="text-left text-c12">
                Montant
              </div>

              <div class="mt-2">
                <inputo
                    type="number"
                    @info="retourMontant"
                />
              </div>
            </div>

            <div class="text-60 text-c12 text-left bloc flex items-center pl-4 mt-6">
              <div>
                Solde : <span class="text-red"> {{ totalFacture() - parseInt(montant) }} Fr</span>
              </div>
            </div>

            <div class="mt-6" v-if="position !== 'CASH' && position !== 'USER_ENGAGEMENT' && position !== 'CLIENT_ASSETS' && !actualise">
              <div class="text-left text-c12">
                Référence du moyen de paiement
              </div>

              <div class="mt-2">
                <inputo @info="retourReference"/>
              </div>
            </div>

            <div v-if="position === 'BANK_CHECK' && !actualise">
              <div class="mt-6">
                <div class="text-left text-c12">
                  Banque*
                </div>

                <div class="mt-2">
                  <inputo
                      @info="retourBanque"
                  />
                </div>
              </div>

              <div class="mt-6">
                <div class="text-left text-c12">
                  Tireur*
                </div>

                <div class="mt-2">
                  <inputo
                      @info="retourPorteur"
                  />
                </div>
              </div>
            </div>

            <div class="mt-6 flex flex-wrap">
              <div
                  class="moyens p-2 pl-4 pr-4 rounded-10 flex mr-3 mb-4"
                  v-for="(item, index) in allPayement" :key="index">
                <div class="text-left">
                  <div class="text-c12">{{item.position}}</div>
                  <div class="text-c10 font-c6">{{ item.montant.toLocaleString() }}</div>
                </div>
                <div class="text-c12 ml-2 text-red cursor-pointer" @click="removePaye(index)">x</div>
              </div>
            </div>

            <div class="mt-4 flex justify-center">
              <buton
                  class="w-2/3"
                  label="Ajouter un nouveau moyen de payement"
                  size="12px"
                  radius="5px"
                  background="#FEA511"
                  height="35px"
                  :charge="charge"
                  @oga="newPayement"
              />
            </div>


            <div
              v-if="error !== null"
              class="mt-6 text-red text-c13"
            >
              {{ error }}
            </div>
          </div>

          <div class="flex">
            <div class="w-full">
              <buton
                label="Enregistrer"
                radius="0px 0px 10px 10px"
                height="55px"
                :charge="charge"
                @oga="continuer"
              />
            </div>
          </div>
        </div>
      </div>
    </popup-base>
  </section>
</template>

<script>
import PopupBase from '../helper/add/popupfat'
import buton from '../helper/add/button'
import ic_agence from '../../assets/icons/agenceGray.svg'
import fermer from '../../assets/icons/fermer.svg'
import multiselect from '../helper/form/multiselect'
import http from "../../plugins/https"
import apiroutes from "../../router/api-routes"
import inputo from '../helper/form/input'

export default {
  name: 'Success',
  components: {
    PopupBase,
    buton,
    multiselect,
    inputo
  },

  props: {
    activation: {
      type: Boolean,
      default: false
    },
    donne: {
      type: Object,
      default: null
    },
    estimation: {
      type: String,
      default: null
    }
  },

  data () {
    return {
      donnes: null,
      wantToMakeOffer: false,
      icons: {
        ic_agence,
        fermer
      },
      date: '',
      error: null,
      charge: false,
      allInfo: ['Espèce', 'Mobile Money', 'Chèque', 'Utiliser les avoirs du client', 'M’engager personnellement'],
      position: '',
      montant: 0,
      reference: '',
      banque: '',
      porteur: '',
      allPayement: [],
      actualise: false,
      myOrder: null
    }
  },

  created() {
    this.wantToMakeOffer = this.activation
  },

  methods: {
    newPayement() {
      if (this.controler() === 'ok') {
        let obj = {
          montant: this.montant,
          position: this.position,
          reference: this.reference,
          banque: this.banque,
          porteur: this.porteur
        }
        this.allPayement.push(obj)
        this.actualise = true
        this.charge = true

        setTimeout(() => {
          this.actualise = false
          this.charge = false
        }, 1000);
      }
    },

    removePaye (index) {
      this.allPayement.splice(index, 1)
    },

    fermer () {
      this.$emit('oga', false)
    },

    retourBanque (answer) {
      this.banque = answer
    },

    retourPorteur (answer) {
      this.porteur = answer
    },


    calcule () {
      let amount = 0
      const data = this.donne.articles

        for (let item in data) {
        //lorsqu'une prise en charge est intégré
          if (this.donne.insuranceSupports.length > 0){
            // if (data[item].product.type === 'GLASS' || data[item].product.type === 'FRAMES') {
            if (data[item].product.type !== 'GLASS') {
              amount = amount + data[item].amount
            }
          } else {
            if (data[item].product.type === 'GLASS') {
              amount = amount + data[item].amount
            }
          }
        }

      //total des prises en charge de type glass
      let insurance = this.donne.financialData.supportAmount
      // for(let item in this.donne.insuranceSupports){
      //   insurance = insurance + this.donne.insuranceSupports[item].glassAmount
      // }

      console.log('assurance', insurance)

      // lorsqu'il n'y a pas de verres dans mes achats
      if (amount === 0) {
        amount = this.donne.financialData.totalAmount + this.donne.financialData.deliveryAmount - this.donne.financialData.discountAmount
      }

      return amount - insurance
    },

    quote (type){
      if (type === 'VERRE'){
        let insurance = 0
        for (let item in this.donne.insuranceSupports) {
          if (this.donne.insuranceSupports[item].glassAmount !== undefined) {
            insurance = insurance + this.donne.insuranceSupports[item].glassAmount
          }
        }
        return insurance
      }
      if (type === 'MONTURE'){

        let insurance = 0
        for(let item in this.donne.insuranceSupports){
          if (this.donne.insuranceSupports[item].frameAmount !== undefined) {
            insurance = insurance + this.donne.insuranceSupports[item].frameAmount
          }

        }

        return insurance
      }
    },

    totalFacture () {
      let amount = 0
      amount = this.donne.financialData.totalAmount - this.donne.financialData.discountAmount - this.donne.financialData.supportAmount + this.donne.financialData.deliveryAmount
      return amount
    },

    continuer () {
      if (this.montant !== '' && this.montant !== null) {
        this.newPayement()
      }
      this.charge = true

      // calcul du total de payement enregistrer
      let total = 0
      for (let item in this.allPayement) {
        total = total + parseInt(this.allPayement[item].montant)
      }

      // Vérification du montant minimum
      if (total >= this.calcule() || this.totalFacture() === total) {
        this.saveFinaly()
      } else {
        this.charge = false
        this.error = "Le montant minimum pour lancer cette commande est de: " + this.calcule()
      }
    },

    controler () {
      this.error = null
      this.charge = true
      if (this.position !== 'Sélectionnez le moyen utilisé' && this.reference !== null && this.montant !== null) {
        if(this.totalFacture() >= this.montant){
          if (this.position === 'BANK_CHECK'){
            if (this.banque.length > 0 && this.porteur.length > 0 && this.reference !== ''){
              return 'ok'
            }
            else {
              this.error = 'La banque et le porteur sont obligatoire'
            }
          } else {
            if (this.position === 'CLIENT_ASSETS') {

              if (this.donne.client.wallet.amount >= this.montant) {
                this.charge = true
                return 'ok'
              } else {
                this.charge = false
                this.error = 'Avoir du client insuffisant pour effectuer cette opération'
              }
            } else {
              this.charge = true
              return 'ok'
            }
          }
        } else {
          this.charge = false
          this.error = 'Désolé vous ne devez pas encaisser plus que ' + this.totalFacture().toLocaleString() + ' Fr'
        }

      } else {
        this.charge = false
        this.error = 'Veuillez remplir les champs pour continuer'
      }
    },

    saveFinaly () {
      this.charge = true

      let obj = null
      let index = 0
      for (let item in this.allPayement) {
       if (this.allPayement[item].position === 'USER_ENGAGEMENT'){
         obj = this.allPayement[item]
         index = item
       }
      }

      if (obj === null) {
        obj = this.allPayement[index]
      }
        let body = {
          estimate: this.estimation,
          paymentAmount: parseInt(obj.montant),
          paymentType: obj.position,
          note: "Commande lancée",
          clientNote: "note",
          isMultiple: true,
          meta: {
            reference: obj.reference,
            banque: obj.banque,
            porteur: obj.porteur
          }
        }

        http.post(apiroutes.baseURL + apiroutes.parseCommande, body)
            .then(response => {
              this.myOrder = response
              this.charge = false
              console.log('coupon response')
              console.log(response)
              this.allPayement.splice(index, 1)
              if (this.allPayement.length > 0) {
                this.lastPaye(response)
              } else {
                this.$emit('oga', response)
              }
            })
            .catch(error => {
              this.charge = false
              console.log(error)
              this.error = "Problème de connexion. Veuillez ressayer"
            })
    },


    // les autres encaissements
    lastPaye (sendResponse) {
      this.charge = true

      let paye = []
      for (let item in this.allPayement) {
        let body = {
          order: this.myOrder.id,
          amount: parseInt(this.allPayement[item].montant),
          paymentType: this.allPayement[item].position,
          note: "string",
          meta: {
            reference: this.allPayement[item].reference,
            banque: this.allPayement[item].banque,
            porteur: this.allPayement[item].porteur
          }
        }

        paye.push(body)
      }

      // console.log('voici mon tableau')
      // console.log(paye)

      http.post(apiroutes.baseURL + apiroutes.payMultiple, {
        payments: paye
      })
          .then(response => {
            this.charge = false
            console.log('final')
            console.log(response)
            this.$emit('oga', sendResponse)
          })
          .catch(error => {
            this.charge = false
            console.log(error)
            this.error = "Problème de connexion. Veuillez ressayer"
          })

    },

    logState (answer) {
      this.wantToMakeOffer = answer
      this.fermer()
    },

    retourReference (answer) {
      this.reference = answer
    },

    retourMontant (answer) {
      if (answer === ''){
        this.montant = 0
      } else {
        this.montant = answer
      }

    },

    retourMoyen (answer) {
      this.position = answer
      if (answer === 'Espèce') {
        this.position = 'CASH'
      }
      if (answer === 'Mobile Money') {
        this.position = 'MOBILE_MONEY'
      }
      if (answer === 'Chèque') {
        this.position = 'BANK_CHECK'
      }
      if (answer === 'M’engager personnellement') {
        this.position = 'USER_ENGAGEMENT'
      }
      if (answer === 'Utiliser les avoirs du client') {
        this.position = 'CLIENT_ASSETS'
      }
    }
  }
}
</script>

<style lang="scss"  scoped>
    @import "../../assets/styles/sass/mixin";
    .success {
      z-index: 999;
    }
    .cardinal {
      width: 42%;
    }

    .moyens{
      background-color: #F5F5F5;
    }

    .success-container {
      font-family: $font-default;
      width: 100%;
      background-color: #F5F5F5;
      height: auto;
      margin: auto;
      border-radius: 12px;
    }
    .bloc{
      min-height: 40px;
      background: rgba(251, 167, 5, 0.2);
      border-radius: 5px;
    }
    @media screen and (max-width: 700px){
      .success-container {
        width: 100%;
        height: 100vh;
      }
      .titre {
        font-size: 18px;
      }
      .bloc1 {
        width: 100%;
      }
      .bloc2 {
        width: 100%;
        font-size: 18px;
      }
      .button {
        width: 100%;
        font-size: 16px;
      }
      .cardinal {
        width: 100%;
      }
    }

    @media screen and (max-width: 1024px) and (min-width: 770px){
      .cardinal{
        width: 65%;
      }
      .titre{
        font-size: 18px;
      }
      .button{
        width: 100%;
        height: 55px;
        font-size: 19px;
      }
    }

    @media screen and (max-width: 769px) and (min-width: 701px){
      .cardinal{
        width: 90%;
      }
      .titre{
        font-size: 18px;
      }
      .button{
        width: 100%;
        height: 55px;
        font-size: 19px;
      }
    }
    @media screen and (max-width: 320px){
      .cardinal{
        width: 100%;
      }
      .titre{
        font-size: 14px;
      }
      .button{
        width: 100%;
        height: 55px;
        font-size: 15px;
      }
    }
</style>
