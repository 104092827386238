<template>
  <div
    class="text-left p-4 cursor-pointer flex items-center"
    :style="{'height': height, 'border': border, 'color': color, 'border-radius': radius, 'background-color': background}"
  >
    <div
      class="flex items-center w-full"
    >
      <div class="w-1/5" @click="openUpload()">
        <icon
          :data="icon"
          height="30"
          width="30"
          class="cursor-pointer text-center"
          original
        />
      </div>

      <div class="ml-2 w-4/5 font-c5 text-c18" @click="openUpload()">
        {{ label }}
      </div>

      <icon
          v-if="index !== null"
          :data="icons.deleter"
          height="18"
          width="18"
          class="cursor-pointer text-center"
          original
          @click="deleteDoc()"
      />
    </div>
  </div>
</template>

<script>
import deleter from '@/assets/icons/delete.svg'

export default {
  name: "Index",

  components: {
  },
  props: {
    icon: {
      type: Object,
      default: null
    },
    label: {
      type: String,
      default: null
    },
    background: {
      type: String,
      default: 'black'
    },
    border: {
      type: String,
      default: 'none'
    },
    radius: {
      type: String,
      default: '0px'
    },
    height: {
      type: String,
      default: '90px'
    },
    color: {
      type: String,
      default: '#fff'
    },
    index: {
      type: Number,
      default: null
    },
    item: {
      type: String,
      default: null
    },
  },

  data () {
    return {
      icons: {deleter}
    }
  },

  created () {
  },

  methods: {
    openUpload () {
      this.$emit('oga', this.item)
    },

    deleteDoc() {
      const obj = {
        url: this.item,
        index: this.index
      }
      this.$emit('deleter', obj)
    },
  }
}
</script>

<style lang="scss" scoped>
.svg-icon {
  fill: white;
}


</style>
