<template>
  <div class="text-left">
    <div class="mt-6 flex w-full">
      <div class="w-1/2">
        <bouton
            label="Télécharger"
            size="14px"
            radius="4px"
            weight="600"
            color="#000000"
            height="54px"
            background="#EAEAEF"
            :icon="icons.imprim"
            @oga="$emit('imprime', true)"
        />
      </div>

      <div class="w-1/2 ml-6">
        <bouton
            label="Envoyer"
            size="14px"
            radius="4px"
            weight="600"
            color="#000000"
            height="54px"
            background="#EAEAEF"
            :icon="icons.send"
        />
      </div>
    </div>

    <div class="bloc bg-white rounded-6 p-6 mt-6 text-left">
      <div class="flex items-center">
        <icon
            :data="icons.avoir"
            height="45"
            width="45"
            class="mr-5 cursor-pointer"
            original
        />
        <div class="w-4/5">
          <div class="text-c16 text-60">
            Avance du client
          </div>
          <div class="text-c24 font-c6 text-yell mt-1">
            <span v-if="donnes.client.wallet === null">0</span>
            <span v-if="donnes.client.wallet !== null"> {{ donnes.client.wallet.amount.toLocaleString() }} </span> FCFA
          </div>
        </div>
      </div>

      <bouton
          v-if="donnes.order === null || donnes.order === undefined"
          label="Enregistrer une avance"
          size="18px"
          radius="4px"
          weight="400"
          color="#fff"
          height="54px"
          background="#FBA705"
          class="mt-6"
          @oga="retourPaie"
      />
    </div>

    <div v-if="donnes.order === null || donnes.order === undefined">
      <bouton
          v-if="donnes.coupon === undefined || donnes.coupon === null"
          label="Enregistrer une prise en charge"
          size="16px"
          radius="4px"
          weight="400"
          color="#fff"
          height="59px"
          :icon="icons.priseEncharge"
          class="mt-6"
          @oga="$emit('assurance', true)"
      />
    </div>

    <div v-if="donnes.order === null || donnes.order === undefined">
      <bouton
          v-if="activeMesure && !donnes.clientMeasures"
          label="Mesures du client"
          size="16px"
          radius="4px"
          weight="400"
          color="#fff"
          height="59px"
          background="#FF8888"
          :icon="icons.edit"
          class="mt-6"
          @oga="$emit('mesure', true)"
      />

      <bouton
          v-if="activeMesure && donnes.clientMeasures"
          label="Modifier les Mesures du client"
          size="16px"
          radius="4px"
          weight="400"
          color="#fff"
          height="59px"
          background="#FF8888"
          :icon="icons.edit"
          class="mt-6"
          @oga="$emit('updateMesure', true)"
      />
    </div>

    <div v-if="donnes.order !== null && donnes.order !== undefined">
      <div v-if="donnes.order.state === 'ESTIMATE_VALIDATED' || donnes.order.state === 'COORDINATION_REJECT'">
        <bouton
            v-if="activeMesure && !donnes.clientMeasures"
            label="Mesures du client"
            size="16px"
            radius="4px"
            weight="400"
            color="#fff"
            height="59px"
            background="#FF8888"
            :icon="icons.edit"
            class="mt-6"
            @oga="$emit('mesure', true)"
        />

        <bouton
            v-if="activeMesure && donnes.clientMeasures"
            label="Modifier les Mesures du client"
            size="16px"
            radius="4px"
            weight="400"
            color="#fff"
            height="59px"
            background="#FF8888"
            :icon="icons.edit"
            class="mt-6"
            @oga="$emit('updateMesure', true)"
        />
      </div>
    </div>

    <div v-if="donnes.clientMeasures">
      <mesure :donnes="donnes"/>
    </div>

  </div>
</template>

<script>
import bouton from '../../helper/add/button'
import user from '../../../assets/icons/user-circle.svg'
import imprim from '../../../assets/icons/imprim.svg'
import send from '../../../assets/icons/send.svg'
import avoir from '../../../assets/icons/avoir.svg'
import edit from '../../../assets/icons/edit.svg'
import priseEncharge from '../../../assets/icons/priseEnCharge.svg'
import mesure from './mesure'

export default {
  name: "Index",

  components: {
    bouton,
    mesure
  },
  props: {
    donnes: {
      type: Object,
      default: null
    }
  },

  data () {
    return {
      icons: {
        user,
        imprim,
        send,
        avoir,
        priseEncharge,
        edit
      },
      devis: null,
      activePaye: false,
      activeMesure: false
    }
  },

  created () {
    let article = this.donnes.articles
    for (let item in article) {
      if (article[item].product.type === 'GLASS') {
        this.activeMesure = true
      }
    }
  },

  methods: {
    retourAdd () {
    },

    retourPaie () {
      this.$emit('payement', true)
    },
  }
}
</script>

<style lang="scss" scoped>
@import "../../../assets/styles/sass/variables.scss";
.svg-icon {
  fill: white;
}

.bloc {
  filter: drop-shadow(0px 4.08108px 10px rgba(0, 0, 0, 0.1));
  z-index: 1;
}

</style>
